<template>
  <div>
    <headers />
    <div class="content">
      <div class="jAPxxm">
        <img class="bg" :src="require('@/assets/image/h5/home/video.png')" alt="" />
        <div class="frPNiP">
          <div class="ksEuhS">
            <div class="cTJIxw">
              <p>数壤科技（苏州）有限公司是以人工智能为驱动，碳管理
与生产及设备管理融合为主要特点的工业数字化转型平台服务商。</p>
            </div>
            <div class="isHFlM">
              <p>数壤科技持续研发并投入应用的生产能源设备一体化平台（数字厂长），是通过植入AI算法的智能物联硬件，使用云端算力构筑起的，以工业大数据为核心，快速服务于制造业中小企业的SaaS平台。
平台拥有自主知识产权的各种人工智能算法、AIoT数采硬件，做到以标准化的SaaS产品快速服务于广大离散制造业中小企业的生产过程、精细能耗及全设备的数字化。通过数据挖掘技术、AI技术为用户提供能耗、人工、碳排、设备等多维度生产成本分析控制服务，在工业过程透明化管理，节能降碳，结合生产过程数据和设备实时状态的人效拉动提升，及设备智能运维等综合方面，帮助企业降本增效。同时利用人工智能+物联网+区块链技术优势为用户提供产品碳足迹和ESG服务，助力构建产业链低碳生态。</p>
            </div>
            <div class="fReABp">
              <button class="insUpY"><a href="/h5/productoverview">查看更多 &gt;</a></button>
              <a class="iZGBsp" id="landing-video-link">
                <div class="kFYMXL">
                  <img src="https://files.blacklake.cn/ow-images/home-2019/icons/video-play.svg" alt="观看产品介绍视频" />观看产品介绍视频
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="home_content">
        <div class="introduce introduce-first" style="padding: 0 15px;">
          <div class="introduce-title"><img :src="require('@/assets/image/introduce.png')" alt=""></div>
          <div class="introduce-to"><a href="/h5/productoverview">详细产品<i class="el-icon-right"></i></a></div>
        </div>
        <div class="introduce" style="padding: 0 15px;">
          <div class="dong">更懂制造业企业的贴心助手</div>
        </div>
        <div class="introduce introduce-second">
          <div class="introduce-content">
            <img class="videoImg" :src="require('@/assets/image/introduce-content.png')" alt="" />
          </div>
          <div class="introduce-text">
            <div class="introduce-text-title">能</div>
            <p>电费节省方案，大幅节省费用。</p>
            <p>碳交易预先方案， 碳市场占领先机。</p>
            <div class="introduce-text-title">产</div>
            <p>排产——优先排产，紧跟订单，精准派工。</p>
            <p>生产——手机、扫脸、触屏多项报工</p>
            <p>屏显——大屏显示工单，实时清晰进度</p>
            <p>设备——OEE显示，实时显示状态</p>
            <div class="introduce-text-title">供</div>
            <p>原料入库—— 入库直接连通生产，数据实时显示</p>
            <div class="introduce-text-title">销</div>
            <p>精准客户推荐，订单自转工单</p>
            <div class="introduce-text-title">管</div>
            <p>整体分析——全程跟踪显示掌握工厂内外。</p>
          </div>
        </div>
        <div class="introduce introduce-3">
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/xiaolv.png')" alt="">
            <p class="names">效率提升</p>
            <div class="line"></div>
            <div class="up">
              <div class="one">
                <div class="up-name">员工效率提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 20%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='20' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">工厂效率提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 30%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='30' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">订单交付效率提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 60%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='60' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/wuliao.png')" alt="">
            <p class="names">物料节省</p>
            <div class="line"></div>
            <div class="up">
              <div class="one">
                <div class="up-name">物料成本节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 10%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='10' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">能源费用节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 20%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='20' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">沟通成本节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 30%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='30' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">用工成本节省</div>
                <div class="up-line">
                  <div class="progress" style="width: 16%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='16' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/fengxian.png')" alt="">
            <p class="names">风险减少</p>
            <div class="line"></div>
            <div class="up">
              <div class="one">
                <div class="up-name">仓储风险减少</div>
                <div class="up-line">
                  <div class="progress" style="width: 20%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='20' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">订单风险减少</div>
                <div class="up-line">
                  <div class="progress" style="width: 50%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='50' :duration='4000' />%
                </div>
              </div>
              <div class="one">
                <div class="up-name">能源风险减少</div>
                <div class="up-line">
                  <div class="progress" style="width: 30%;">
                    <div class="progress-bg down"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-bottom down"></i>
                  <CountTo :startVal='0' :endVal='30' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
          <div class="datas">
            <img class="icon" :src="require('@/assets/image/xiaoshou.png')" alt="">
            <p class="names">销售提升</p>
            <div class="line"></div>
            <div class="up" style="display: block;">
              <div class="one">
                <div class="up-name">订单进度提高</div>
                <div class="up-line">
                  <div class="progress" style="width: 50%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='50' :duration='4000' />%
                </div>
              </div>
              <div class="one" style="margin-top:10px">
                <div class="up-name">销售获客提升</div>
                <div class="up-line">
                  <div class="progress" style="width: 50%;">
                    <div class="progress-bg"></div>
                  </div>
                </div>
                <div class="up-num">
                  <i class="el-icon-top add"></i>
                  <CountTo :startVal='0' :endVal='50' :duration='4000' />%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="introduce introduce-4">
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well1.png')" alt="" /></div>
            <div class="text">
              <p class="names">一天上线</p>专人现场部署，一天上线
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well2.png')" alt="" /></div>
            <div class="text">
              <p class="names">轻松上手</p>员工简单学会,10分钟上手
            </div>
          </div>
        </div>
        <div class="introduce introduce-4">
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well4.png')" alt="" /></div>
            <div class="text">
              <p class="names">全厂打通</p>各功能数据联动,打通全厂内容
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well3.png')" alt="" /></div>
            <div class="text">
              <p class="names">配置灵活</p>可操作空间大,灵活配置
            </div>
          </div>
        </div>
        <div class="introduce introduce-4">
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well5.png')" alt="" /></div>
            <div class="text">
              <p class="names">专业实施</p>专业人才现场安装,解惑答疑
            </div>
          </div>
          <div class="godDone">
            <div class="icon"><img :src="require('@/assets/image/well6.png')" alt="" /></div>
            <div class="text">
              <p class="names">专家配置</p>专家提供方案,助力企业
            </div>
          </div>
        </div>
      </div>
      <div class="colorDiv"></div>
      <div class="home1">
        <div class="home4">
          <div class="home4_cotent">
            <div class="home4_main">
              <div class="home4_title">
                <div class="home4_left">
                  <div class="home4_p1">
                    <div class="home_title_line"></div>
                    <div class="home_title_pt">商城与大数据平台</div>
                  </div>
                  <div class="home_title_xs">携手共进，打造制造业企业联盟</div>
                </div>
                <div class="home_title_product">
                  <a href="/h5/productoverview">详细产品<i class="el-icon-right home_title_icon"></i></a>
                </div>
              </div>

              <div class="home4_cardList">
                <div class="home4_card">
                  <div class="home4_card_div">
                    <img :src="require('@/assets/image/gongyeping.png')" alt="" class="card_img1" />
                  </div>
                  <div class="home4_card_title">数壤工业品商城</div>
                  <div class="home4_card_content card_one">
                    专业工业品商城，属于制造业的专属商城
                  </div>
                </div>

                <div class="home4_card">
                  <div class="home4_card_div">
                    <img :src="require('@/assets/image/dianshang.png')" alt="" class="card_img2" />
                  </div>
                  <div class="home4_card_title">数壤跨境电商</div>
                  <div class="home4_card_content">打通境外渠道，<br />开辟新鲜路径。</div>
                </div>

                <div class="home4_card">
                  <div class="home4_card_div">
                    <img :src="require('@/assets/image/zhuanjia.png')" alt="" class="card_img3" />
                  </div>
                  <div class="home4_card_title">数壤专家中心</div>
                  <div class="home4_card_content">集中智囊团队，<br />解决企业问题。</div>
                </div>

                <div class="home4_card">
                  <div class="home4_card_div">
                    <img :src="require('@/assets/image/kecheng.png')" alt="" class="card_img4" />
                  </div>
                  <div class="home4_card_title">数壤精选课程</div>
                  <div class="home4_card_content">针对企业痛点，<br />知识引领力量。</div>
                </div>

                <div class="home4_card">
                  <div class="home4_card_div">
                    <img :src="require('@/assets/image/chanye.png')" alt="" class="card_img5" />
                  </div>
                  <div class="home4_card_title">数壤产业大数据平台</div>
                  <div class="home4_card_content">聚沙成塔，打造制造业伊甸</div>
                </div>

                <div class="home4_card">
                  <div class="home4_card_div">
                    <img :src="require('@/assets/image/hangye.png')" alt="" class="card_img6" />
                  </div>
                  <div class="home4_card_title">数壤行业大数据平台</div>
                  <div class="home4_card_content">比肩共进，整体行业升级</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home5">
          <div class="home5_title">
            <div class="home5_title_line"></div>
            <div class="home5_title_p1">工业品碳足迹</div>
          </div>
          <div class="home5_explain">
            基于生产、能源、设备一体化工业大数据，多种AI和算法协同，应用区块链技术构筑起关键节点数据加密和确权，形成以真实数据为底层，产业链为依托的工业品碳足迹服务平台。
          </div>
          <div class="home5_img_div">
            <img :src="require('@/assets/image/h5/home/metaverse.png')" />
          </div>
        </div>
        <div class="home6">
          <div class="home6_title">
            <div class="home6_title_line"></div>
            <div class="home6_title_p1">快速走进数壤</div>
          </div>
          <div class="bottom">
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go1.png')" alt="">
              <a href="/h5/productoverview" class="btn">BOSS中心</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go2.png')" alt="">
              <a href="/h5/productoverview" class="btn">能源双碳管理</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go3.png')" alt="">
              <a href="/h5/productoverview" class="btn">供应链管理</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go4.png')" alt="">
              <a href="/h5/productoverview" class="btn">制造管理</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go5.png')" alt="">
              <a href="/h5/productoverview" class="btn">数壤商城</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go6.png')" alt="">
              <a href="/h5/productoverview" class="btn">订单管理</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go7.png')" alt="">
              <a href="/h5/productoverview" class="btn">人才中心</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go8.png')" alt="">
              <a href="/h5/productoverview" class="btn">设备OEE</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go9.png')" alt="">
              <a href="/h5/productoverview" class="btn">数据分析服务</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go10.png')" alt="">
              <a href="/h5/productoverview" class="btn">数壤大学</a>
            </div>
            <div class="imgbox">
              <img :src="require('@/assets/image/h5/home/go11.png')" alt="">
              <a href="/h5/productoverview" class="btn">物联设备</a>
            </div>
          </div>
        </div>
        <div class="home7">
          <div class="home7_title">
            <div class="home7_title_line"></div>
            <div class="home7_title_p1">部分客户及合作伙伴</div>
          </div>
          <div class="home7_info">政府、园区及机构、行业企业、合作院校</div>
          <div class="home7_img_div">
            <div v-for="(item, key) in imgArr" :key="key">
              <img :src="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/H5/hend';
import footers from '@/components/H5/foot';
import CountTo from 'vue-count-to';
export default {
  components: {
    headers,
    footers,
    CountTo,
  },
  data() {
    return {
      imgArr: [
        require("@/assets/image/shandong.png"),
        require("@/assets/image/boshan.png"),
        require("@/assets/image/maanshan.png"),
        require("@/assets/image/wuzhong.png"),
        require("@/assets/image/CAICT.png"),
        require("@/assets/image/SDIIT.png"),
        require("@/assets/image/TLSP.png"),
        require("@/assets/image/Universad.png"),
        require("@/assets/image/CAS.png"),
        require("@/assets/image/huawei.png"),
        require("@/assets/image/CHG.png"),
        require("@/assets/image/ZENCAPITAL.png"),
        require("@/assets/image/dongfeng.png"),
        require("@/assets/image/Simand.png"),
        require("@/assets/image/Provac.png"),
        require("@/assets/image/MHG.png"),
        require("@/assets/image/ZENTO.png"),
        require("@/assets/image/xinheng.png"),
        require("@/assets/image/czschool.png"),
        require("@/assets/image/suzhida.png"),
        require("@/assets/image/sukeda.png"),
        require("@/assets/image/suda.png"),
        require("@/assets/image/jsschool.png"),
        require("@/assets/image/nanligong.png"),
      ],
    }
  }
}
</script>
<style lang="less" scoped>
a {
  color: white;
}

.content {
  min-width: 320px;
  max-width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  font-size: 14px;
  line-height: 1.6;

  .jAPxxm {
    position: relative;
    width: 100%;
    height: 476px;

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .frPNiP {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      .ksEuhS {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 324px;
      }

      .cTJIxw {
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;
        display: flex;
        flex-direction: column;
      }

      .isHFlM {
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
      }

      .fReABp {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .insUpY {
          width: 180px;
          height: 30px;
          line-height: 30px;
          background: #308971;
          border-radius: 40px;
          border: none;
          color: white;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
        }

        .iZGBsp {
          margin-top: 20px;

          img {
            width: 12px;
            height: 12px;
            margin-right: 10px;
          }

          .kFYMXL {
            font-size: 12px;
            line-height: 30px;
            color: #308971;
            height: 30px;
            border: 1px solid #308971;
            border-radius: 15px;
            text-align: center;
            display: flex;
            flex-direction: row;
            -webkit-box-align: center;
            align-items: center;
            padding: 0px 40px;
          }
        }
      }
    }
  }

  .home_content {
    margin: auto;
    overflow: hidden;

    .introduce {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .introduce-title {
        position: relative;
        padding-left: 13px;
        height: 24px;

        img {
          width: 79px;
          height: 24px;
        }

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 3px;
          height: 24px;
          background: #308971;
          border-radius: 3px;
        }
      }

      .introduce-to {
        color: #308971;
        font-size: 14px;

        a {
          color: #308971;
        }
      }

      .dong {
        margin-top: 10px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }

      .introduce-content {
        position: relative;

        img {
          width: 240px;
          height: 190px;
        }
      }

      .introduce-text {
        width: 182px;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 8px;
        line-height: 12px;

        .introduce-text-title {
          position: relative;
          margin-top: 7px;
          font-size: 12px;
          font-weight: bold;

          &::before {
            position: absolute;
            left: -10px;
            top: 10px;
            content: '';
            width: 5px;
            height: 5px;
            background: #308971;
            border-radius: 50%;
          }
        }
      }

      .datas {
        margin: 8px;
        width: 165px;
        height: 159px;
        background: #FFFFFF;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin-top: 10px;
          width: 35px;
          height: 35px;
        }

        .names {
          margin-top: 5px;
          font-size: 14px;
          line-height: 20px;
        }

        .line {
          margin-top: 5px;
          width: 56px;
          height: 1px;
          border-top: 1px solid #F5F5F5;
        }

        .up {
          margin-top: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 70px;

          .one {
            height: 11px;
            line-height: 11px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .up-name {
              margin-right: 5px;
              width: 72px;
              color: rgba(0, 0, 0, 0.65);
              font-size: 8px;
              text-align: right;
            }

            .up-line {
              width: 40px;
              height: 3px;
              background: #F5F5F5;
              border-radius: 4px;

              .progress-bg {
                border-radius: 4px;
                background: #F5222D;
                height: 3px;
                animation: animationLine 4s forwards;

                &.down {
                  background: #308971;
                }
              }

              @keyframes animationLine {
                0% {
                  width: 0;
                }

                100% {
                  width: 100%;
                }
              }
            }

            .up-num {
              display: flex;
              align-items: center;
              width: 37px;
              font-size: 8px;

              .add {
                color: #F5222D;
              }

              .down {
                color: #308971;
              }
            }
          }
        }
      }

      .godDone {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .icon {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 26px;
          height: 26px;
          background: #FFFFFF;
          border-radius: 5px;

          img {
            width: 15px;
            height: 15px;
          }
        }

        .text {
          width: calc(100% - 26px);
          margin-left: 10px;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 11px;

          .names {
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      &.introduce-first {
        margin-top: 30px;
      }

      &.introduce-second {
        margin-top: 7px;
      }

      &.introduce-3 {
        flex-wrap: wrap;
        padding: 5px;
        margin: auto;
        box-sizing: border-box;
      }

      &.introduce-4 {
        padding: 10px;
        margin: auto;
        margin-top: 10px;
      }
    }
  }

  .colorDiv {
    width: 100%;
    height: 513px;
    background: rgba(48, 137, 113, 0.05);
    margin-top: -465px;
  }

  .home1 {
    background: #fff;

    .home4 {
      background: #fff;
      width: 100%;
      padding-top: 30px;

      .home4_cotent {
        height: 394px;
        background: url("~@/assets/image/h5/home/dm_bg.png") no-repeat;
        background-size: 100% 100%;

        .home4_main {
          padding: 0 16px;
          margin: 0 auto;
          overflow: hidden;

          .home4_title {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;

            .home4_left {
              .home4_p1 {
                display: flex;
                font-family: PingFangSC-Medium, PingFang SC;

                .home_title_line {
                  width: 3px;
                  height: 23px;
                  background: #fff;
                  border-radius: 2px;
                  margin-right: 10px;
                }

                .home_title_pt {
                  height: 23px;
                  font-weight: 500;
                  font-size: 16px;
                  color: rgba(255, 255, 255, 0.85);
                }
              }

              .home_title_xs {
                margin-top: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-size: 10px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.85);
                line-height: 14px;
              }
            }

            .home_title_product {
              margin-left: auto;

              a {
                color: #fff;
              }
            }
          }

          .home4_cardList {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .home4_card {
              margin-top: 20px;
              width: 107px;
              height: 123px;
              background: #fff;
              border-radius: 5px;

              .home4_card_div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 12px auto 0;
                background: rgba(48, 137, 113, 0.1);
                text-align: center;
                border-radius: 50%;

                .card_img1 {
                  width: 20px;
                  height: 15px;
                }

                .card_img2 {
                  width: 18px;
                  height: 18px;
                }

                .card_img3 {
                  width: 19px;
                  height: 16px;
                }

                .card_img4 {
                  width: 17px;
                  height: 20px;
                }

                .card_img5 {
                  width: 19px;
                  height: 19px;
                }

                .card_img6 {
                  width: 20px;
                  height: 18px;
                }
              }

              .home4_card_title {
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                line-height: 17px;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 5px;
              }

              .home4_card_content {
                width: 92px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 14px;
                margin: 0 auto;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .home5 {
      padding: 0 15px;
      margin: 30px auto 0;

      .home5_title {
        display: flex;

        .home5_title_line {
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
          margin-right: 10px;
        }

        .home5_title_p1 {
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 23px;
        }
      }

      .home5_explain {
        margin-top: 10px;
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 14px;
      }

      .home5_img_div {
        margin-top: 20px;

        img {
          width: 345px;
          height: 353px;
        }
      }
    }

    .home6 {
      padding: 0 10px;
      margin: 30px auto 0;

      .home6_title {
        padding: 0 5px;
        display: flex;

        .home6_title_line {
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
          margin-right: 10px;
        }

        .home6_title_p1 {
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 23px;
        }
      }

      .bottom {
        margin-top:10px;
        display: flex;
        flex-wrap: wrap;
        .imgbox{
          position: relative;
          margin: 10px 5px 0;
          width: 108px;
          height: 153px;
          img{
            display: block;
            width: 100%;
            height:100%;
          }
          .btn{
            position: absolute;
            left:5px;
            bottom: 7px;
            width: 98px;
            height: 20px;
            line-height: 20px;
            background: #308971;
            border-radius: 15px;
            text-align: center;
            font-size: 10px;
            color: #fff;
          }
        }
      }
    }

    .home7 {
      background: rgba(48, 137, 113, 0.05);
      padding: 15px;

      .home7_title {
        display: flex;

        .home7_title_line {
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
          margin-right: 10px;
        }

        .home7_title_p1 {
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 23px;
        }
      }

      .home7_info {
        margin-top: 10px;        
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 14px;
      }

      .home7_img_div {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        div {
          margin-bottom: 10px;
          img {
            width: 108px;
            height: 48px;
          }
        }
      }
    }
  }
}
</style>
